import { Injectable } from "@angular/core";
import { HttpService } from "./api.service";
import { Router } from "@angular/router";
import { Appointment } from "../models/appointment";
import { Photo } from "../models/photo";
import { AlaCarte } from "../models/ala-carte";
import { UserService } from "./user.service";
import { Order } from "../models/order";
import { Package } from "../models/package";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { DateTime } from "luxon";
import * as moment from "moment";

@Injectable({ providedIn: "root" })
export class AppointmentService {
  constructor(
    private http: HttpService,
    private router: Router,
    private user: UserService
  ) {}

  /**
   * Update an order.
   *
   * @param {Order} order
   * @returns {Promise<Order>}
   */
  updateOrder(order: Order): Promise<any> {
    return this.http.put(`/order/id/${order.id}`, order).toPromise();
  }

  /**
   * Find a photo.
   *
   * @param {string | number} photoId
   * @returns {any}
   */
  findPhoto(photoId: string) {
    return this.http
      .get(`/photo/single/${photoId}`)
      .pipe(map((photo) => photo.data[0]));
  }

  getPastAppointments(user_id: number): Observable<Appointment[]> {
    const body = {
      get: ["*"],
      sort: {
        id: "desc",
      },
      where: {
        user_id,
        condition: [{ and: ["date", "<=", moment().toDate()] }],
      },
      join: [
        {
          table: "Location",
          // returnType: "object",
          joinType: "left join",
          get: ["*"],
          as: "location",
          on: [{ Appointment: "location_id", Location: "id" }],
        },
        {
          table: "Child",
          joinType: "left join",
          distinct: "id",
          get: ["*"],
          as: "children",
          on: [{ Appointment: "id", Child: "appointment_id" }],
        },
        {
          table: "Package",
          returnType: "object",
          joinType: "left join",
          get: ["*"],
          as: "package",
          distinct: "package_id",
          on: [{ Appointment: "package_id", Package: "id" }],
        },
        {
          table: "Order",
          joinType: "inner join",
          include: true,
          get: ["*"],
          as: "order",
          on: [
            {
              Appointment: "id",
              Order: "appointment_id",
            },
          ],
          where: {
            originating_order: true,
          },
        },
      ],
    };

    return this.http
      .post(`/appointment/get`, body)
      .pipe(map((res) => res.data));
  }

  /**
   * Get Appointments
   * res [{}]
   */
  getAppointments(asUser?: number): Observable<Appointment[]> {
    return this.http.get(`/appointment/all${asUser ? "/" + asUser : ""}`).pipe(
      map((res: Appointment[]) => {
        return res;
      })
    );
  }

  /**
   * Get all photos for an appointment.
   *
   * @param {number} apptId
   * @returns {Promise<any[]>}
   */
  getPhotosForAppointment(apptId: number): Promise<Appointment> {
    return this.http
      .get(`/appointment/photos/${apptId}`)
      .toPromise()
      .then((res) => {
        return res.data as Appointment;
      });
  }

  /**
   * Get a Single appointment.
   *
   * req: appointmentId:number
   * res {}
   */
  getAppointment(appointmentId: number): Observable<Appointment> {
    return this.http.get(`/appointment/single/${appointmentId}`);
  }

  getApp(appointmentId: number): Promise<Appointment> {
    const data = {
      get: ["*"],
      where: { id: appointmentId },
      returnType: "object",
      // 'join': [{
      //   'table': 'User',
      //   'distinct': 'id',
      //   'joinType': 'inner join',
      //   'returnType': 'object',
      //   'as': 'user',
      //   'on': [
      //     {'User': 'id', 'Appointment': 'user_id'}
      //   ],
      //   'join': [{
      //     'table': 'Person',
      //     'distinct': 'id',
      //     'joinType': 'inner join',
      //     'returnType': 'object',
      //     'as': 'person',
      //     'on': [
      //       {'Person': 'user_id', 'User': 'id'}
      //     ]
      //   }]
      // }]
    };

    return this.http
      .post(`/appointment/get`, data)
      .toPromise()
      .then((res) => {
        return res.data as any;
      });
  }

  /**
   * Get all active appointments.
   *
   * @returns {Promise<Appointment[]>}
   */
  getActiveAppointments(): Promise<Appointment[]> {
    const data = {
      get: ["*"],
      where: { active: true },
      join: [
        {
          table: "User",
          joinType: "inner join",
          returnType: "object",
          as: "user",
          on: [{ User: "id", Appointment: "user_id" }],
          join: [
            {
              table: "Person",
              joinType: "inner join",
              as: "person",
              on: [{ Person: "user_id", User: "id" }],
            },
          ],
        },
      ],
    };

    return this.http
      .post(`/appointment/get`, data)
      .toPromise()
      .then((res) => {
        return res.data as Appointment[];
      });
  }

  /**
   * Only return children where some data has been provided.
   *
   * @param {any[]} children
   * @returns {any[]}
   */
  cleanChildren(children: any[]) {
    const c = children.map((child) => {
      child.name = child.name || null;
      child.age = child.age || null;
      child.gender = child.gender || null;
      return child;
    });

    return c.filter((child) => {
      return child.name || child.age || child.gender;
    });
  }

  /**
   * Get Children for an appointment.
   *
   * @param {string} apptId
   */
  getChildren(apptId: number) {
    return this.http
      .get(`/appointment/children/${apptId}`)
      .pipe(map((children: any) => children.data));
  }

  /**
   * Create a Single Appointment
   * req: appointment:Appointment
   * res {}
   */
  createAppointment(
    date: any,
    children: any,
    pkg: any,
    token: any,
    donation: any
  ): Promise<any> {
    const d = date.seconds(0).milliseconds(0).toDate(),
      kids = children
        ? this.cleanChildren(
            children && children.hasOwnProperty("children")
              ? children.children
              : []
          )
        : [],
      offset = DateTime.now().offset;

    return this.http
      .post("/appointment/create", {
        date: d,
        children: kids,
        pkg: pkg.id,
        amount: pkg.price,
        token,
        donation,
        offset,
      })
      .toPromise();
  }

  /**
   * Update a Single Appointment
   * req: appointment:Appointment
   * res {}
   */
  updateAppointment(appointment: Appointment): Promise<Appointment> {
    return this.http
      .post(`/appointment/update/${appointment.id}`, appointment)
      .toPromise()
      .then((res) => {
        return res.data as Appointment;
      });
  }

  /**
   * Get User Appointments
   * res [{}]
   */
  getUserAppointments(userId: number): Observable<Appointment[]> {
    return this.http
      .get(`/appointment/get-user-appointments/${userId}`)
      .pipe(map((res) => res?.data || []));
  }

  /**
   * Get Upcoming Appointments
   * res [{}]
   */
  getUpcomingAppointments(userId: number): Observable<Appointment[]> {
    return this.http
      .post(`/appointment/get`, {
        get: ["*", "count(photo_count.id)"],
        sort: { date: "asc" },
        where: {
          user_id: userId,
          condition: [
            { and: ["active", "=", true] },
            { and: ["deleted_at", "is", null] },
            { and: ["date", ">", new Date()] },
          ],
        },
        join: [
          {
            table: "Location",
            returnType: "object",
            get: ["*"],
            as: "location",
            on: [{ Appointment: "location_id", Location: "id" }],
          },
          {
            table: "Child",
            get: ["*"],
            distinct: "id",
            as: "children",
            on: [{ Appointment: "id", Child: "appointment_id" }],
          },
          {
            table: "Package",
            returnType: "object",
            get: ["*"],
            as: "package",
            on: [{ Appointment: "package_id", Package: "id" }],
          },
          {
            table: "Order",
            returnType: "object",
            joinType: "left join",
            get: ["*"],
            as: "order",
            on: [
              {
                Appointment: "id",
                Order: "appointment_id",
              },
            ],
            where: {
              originating_order: true,
            },
          },
          {
            get: ["id"],
            table: "Photo",
            joinType: "left join",
            as: "photo_count",
            on: [{ Photo: "appointment_id", Appointment: "id" }],
          },
        ],
      })
      .pipe(map((res) => res?.data || []));
  }

  /**
   * Get All Packages
   * res [{}]
   */
  getAllPackages(): Promise<any[]> {
    return this.http
      .get(`/package/all`)
      .toPromise()
      .then((res) => {
        return res.data.response as any[];
      });
  }

  /**
   * Get Single Photo
   * res {}
   */
  getPhotoById(photoId: number): Promise<Photo> {
    return this.http
      .get(`/photo/id/${photoId}`)
      .toPromise()
      .then((res) => {
        return res.data as Photo;
      });
  }

  /**
   * Get the photo sizes.
   *
   * @returns {Promise<any[]>}
   */
  getPhotoSizes(): Promise<any[]> {
    return this.http
      .get(`/photosize`)
      .toPromise()
      .then((res) => {
        return res.data as any[];
      });
  }

  /**
   * Create the ala carte stuff.
   *
   * @param alaCarte
   * @returns {Promise<any>}
   */
  createAlaCarte(alaCarte: any): Promise<any> {
    let sendData = {
      carte: alaCarte,
    };
    return this.http
      .post("/appointmentalacarte/create", sendData)
      .toPromise()
      .then((res) => {
        return res.data as any;
      });
  }

  /**
   * Get the ala carte appointments.
   *
   * @param data
   * @returns {Promise<any[]>}
   */
  getCarteAppointments(data: any): Promise<any[]> {
    return this.http
      .post(`/appointmentalacarte/get-carte-appointments/${data.appId}`, data)
      .toPromise()
      .then((res) => {
        return res.data as any[];
      });
  }

  /**
   * Update the ala carte.
   *
   * @param {AlaCarte} carte
   * @returns {Promise<AlaCarte>}
   */
  updateAlaCarte(carte: AlaCarte): Promise<AlaCarte> {
    return this.http
      .put(`/appointmentalacarte/id/${carte.id}`, carte)
      .toPromise()
      .then((res) => {
        return res.data as AlaCarte;
      });
  }

  /**
   * Remove something from ala carte.
   *
   * @param {AlaCarte} carte
   * @returns {Promise<AlaCarte>}
   */
  removeCarte(carte: AlaCarte): Promise<AlaCarte> {
    return this.http
      .del(`/appointmentalacarte/id/${carte.id}`)
      .toPromise()
      .then((res) => {
        return res.data as any;
      });
  }

  /**
   * Get a QR Code string from an apptId.
   *
   * @param apptId
   * @returns {string}
   */
  getQRCodeString(apptId): string {
    const id = parseInt(apptId, 10).toString(16),
      len = id.length,
      zerosNeeded = 8 - len;

    return Array(zerosNeeded).fill(0).join("") + id;
  }

  /**
   * Get a QR code for a given appointment.
   *
   * @param {string} sessionId
   * @returns {Promise<any>}
   */
  getQRCode(sessionId: string) {
    return this.http.get(`/appointment/qr-code/${sessionId}`).toPromise();
  }

  /**
   * Get the appointments for a given day.
   *
   * @param {string} date
   */
  getBookedAppt(start: string, end: string, location?: string) {
    const params = JSON.parse(
      JSON.stringify({
        start,
        end,
        location,
      })
    );

    return this.http.get("/appointment/booked", params);
  }

  /**
   * Actually order the ala carte photos.
   *
   * @param data
   * @returns {Promise<any>}
   */
  alaCarteOrder(data: any): Promise<any> {
    return this.http
      .post(`/appointmentalacarte/order`, data)
      .toPromise()
      .then((res) => {
        return res.data as any;
      });
  }

  /**
   * Cancel an appointment.
   *
   * @param appt
   * @returns {Promise<any>}
   */
  cancelAppt(appt: any): Promise<any> {
    return this.http
      .post(`/appointment/cancel/${appt.id}`, {
        bypass_fee: appt.bypass_fee,
      })
      .toPromise()
      .then((res) => {
        return res.data as any;
      });
  }

  /**
   * Get all upcoming appointments.
   *
   * @returns {Promise<any>}
   */
  getUpcoming(): Promise<any> {
    return this.http
      .post(`/appointment/get-after-date`, {
        date: DateTime.now().toFormat("yyyy-MM-dd"),
        user_id: this.user?.userId,
      })
      .toPromise()
      .then((res) => {
        return res.data as any;
      });
  }

  /**
   * Get Fulfillment Appointments
   * res [{}]
   */
  getFulfillment(): Promise<Appointment[]> {
    return this.http
      .get(`/appointment/fulfillment`)
      .toPromise()
      .then((res) => {
        return res.data as Appointment[];
      });
  }

  /**
   * Get a package by id.
   *
   * @param {number} packageId
   * @returns {Promise<Package>}
   */
  getPackage(packageId: number) {
    return this.http
      .get(`/package/id/${packageId}`)
      .pipe(map((res) => res.data as Package));
  }

  /**
   * Gets a single order.
   *
   * @param {number} orderId
   */
  getOrder(apptId: number): Observable<Order> {
    return this.http.get(`/order/by-appt/${apptId}`);
  }

  /**
   * Gets a single order.
   *
   * @param {string} dateTimeStr
   */
  convertUTCToLocal(dateTimeStr: string) {
    const utcTime = moment(dateTimeStr).format("MM/DD/YYYY h:mm A");
    return moment.utc(utcTime);
  }
}
