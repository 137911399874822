import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class TimezoneOverrideService {

  constructor() { }

  // Formats the time to ignore timezone
  getTimeWithoutZone(time) {
    // Try parsing with different possible formats
    const formats = ["HH:mm:ssZZ", "H:mm:ssZZ", "HH:mm", "H:mm"];
    
    // Find a valid DateTime object
    const parsedTime = formats
      .map(format => DateTime.fromFormat(time, format, {setZone: true}))
      .find(dt => dt.isValid);
  
    if (!parsedTime) {
      throw new Error(`Invalid time format: ${time}`);
    }
  
    // Format the parsed time into a consistent format (e.g., HH:mm:ss)
    return parsedTime.toFormat("HH:mm");
  }
}
