import { Component, OnInit, Input } from '@angular/core';
import { Appointment } from "../../models/appointment";
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DateTime } from "luxon";
import { environment } from '@environment';

@Component({
  selector: 'app-cancel-appointment',
  templateUrl: './cancel-appointment.component.html',
  styleUrls: ['./cancel-appointment.component.scss']
})
export class CancelAppointmentComponent implements OnInit {
  @Input() appointment: Appointment; 
  @Input() cb?: (record: any) => void;
  @Input() admin?: boolean = false;
  bypassFee: boolean = false;
  refundAmount: number;
  reservationAmount: number;
  isWithin48: boolean = false;
  giftCardAmount: number = 0;
  reservationFee: number = environment.reservationFee || 2500;

  constructor(
    private bsModalRef: BsModalRef
  ) { }

  ngOnInit() {
    this.setRefundAmount();
  }

  setRefundAmount() {
    const duration = DateTime.fromISO(this.appointment.date)
      .minus({ hours: 48 })
      .diff(DateTime.now());
    const chargeAmount = Number(this.appointment.order.price) // amount charged on cred card (in cents)
    const deductionInCents = this.getCancelFee(chargeAmount);

    this.reservationAmount = this.bypassFee ? 0 : this.reservationFee;
    this.giftCardAmount = this.appointment.gift_card_applied || 0;
    this.refundAmount = Math.max(0, chargeAmount - deductionInCents)

    if (duration.valueOf() < 0) {
      // Subract reservation fee if appointment is within 2 days
      this.refundAmount = Math.max(0, this.refundAmount - this.reservationAmount); 
      this.isWithin48 = true;
    }

    this.refundAmount = Math.round(this.refundAmount * 100) / 100; // round to two decimal places
  }

  getCancelFee(amount: number) {
    return Math.round(amount * environment.cancellationMult) + environment.cancellationFee;
  }

  confirm() {
    this.appointment.bypass_fee = this.bypassFee;
    if (this.cb) {
      this.cb(this.appointment);
    }

    this.hide();
  }


  hide() {
    this.bsModalRef.hide();
  }

}
